import {React} from "react";
import {Modal} from "react-bootstrap";
import {VehicleReservationOnlyForm} from "./VehicleReservationOnlyForm";
import styles from "./VehicleReservationOnlyModal.module.scss";

export default function VehicleReservationOnlyModal({
  disableVehicleSelection,
  onHide,
  show,
  selectedProgram,
  selectedPricingOptionIndex,
}) {
  return (
    <Modal contentClassName={styles.modalContent} onHide={onHide} show={show}>
      <Modal.Header closeButton style={{borderBottom: 0}}></Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <VehicleReservationOnlyForm
          disableVehicleSelection={disableVehicleSelection}
          selectedProgram={selectedProgram}
          selectedPricingOptionIndex={selectedPricingOptionIndex}
        />
      </Modal.Body>
    </Modal>
  );
}
